"use strict";

// Class definition
var KTLayoutSearch = function() {
    // Private variables
    var element;
    var formElement;
    var mainElement;
    var resultsElement;
    var wrapperElement;
    var emptyElement;

    var preferencesElement;
    var preferencesShowElement;
    var preferencesDismissElement;
    
    var advancedOptionsFormElement;
    var advancedOptionsFormShowElement;
    var advancedOptionsFormCancelElement;
    var advancedOptionsFormSearchElement;
    
    var searchObject;
    var peticio;
    // Private functions
    var processs = function(search) {
        var timeout = setTimeout(function() {
            // Hide recently viewed
            if(mainElement != null){
                mainElement.classList.add('d-none');
            }

            var texto = search.inputElement.value;
            var url = Routing.generate('aj_producto_search', { 'text' : texto});

            peticio = $.ajax({
                'url': url,
                'type': "GET",
                'async'     : true,
                'cache'     : true,
                'timeout'   : 10000,
                'success'   : function(html){
                    $(resultsElement.classList.remove('d-none'));
                    $('div.resultsbody').html(html);
                },
                complete: function(){
                    console.log("ya ha acabat");
                    search.complete();
                },
                'error'     : function(html){
                    console.log(html);
                }
            })
            // Complete search
        
        }, 500);
    }

    var clear = function(search) {
        console.log("neteja");
        peticio.abort();
        // Show recently viewed
        if(mainElement != null){
            mainElement.classList.remove('d-none');
        }
        // Hide results
        resultsElement.classList.add('d-none');
        // Hide empty message 
        emptyElement.classList.add('d-none');
    }    

    var handlePreferences = function() {
        // Preference show handler
        preferencesShowElement.addEventListener('click', function() {
            wrapperElement.classList.add('d-none');
            preferencesElement.classList.remove('d-none');
        });

        // Preference dismiss handler
        preferencesDismissElement.addEventListener('click', function() {
            wrapperElement.classList.remove('d-none');
            preferencesElement.classList.add('d-none');
        });
    }

    var handleAdvancedOptionsForm = function() {
        // Show
        advancedOptionsFormShowElement.addEventListener('click', function() {
            wrapperElement.classList.add('d-none');
            advancedOptionsFormElement.classList.remove('d-none');
        });

        // Cancel
        advancedOptionsFormCancelElement.addEventListener('click', function() {
            wrapperElement.classList.remove('d-none');
            advancedOptionsFormElement.classList.add('d-none');
        });

        // Search
        advancedOptionsFormSearchElement.addEventListener('click', function() {
            
        });
    }

    // Public methods
	return {
		init: function() {
            // Elements
            element = document.querySelector('#kt_header_search');

            if (!element) {
                return;
            }

            wrapperElement = element.querySelector('[data-kt-search-element="wrapper"]');
            formElement = element.querySelector('[data-kt-search-element="form"]');
            mainElement = element.querySelector('[data-kt-search-element="main"]');
            resultsElement = element.querySelector('[data-kt-search-element="results"]');
            emptyElement = element.querySelector('[data-kt-search-element="empty"]');

            preferencesElement = element.querySelector('[data-kt-search-element="preferences"]');
            //preferencesShowElement = element.querySelector('[data-kt-search-element="preferences-show"]');
            //preferencesDismissElement = element.querySelector('[data-kt-search-element="preferences-dismiss"]');

            //advancedOptionsFormElement = element.querySelector('[data-kt-search-element="advanced-options-form"]');
            //advancedOptionsFormShowElement = element.querySelector('[data-kt-search-element="advanced-options-form-show"]');
            //advancedOptionsFormCancelElement = element.querySelector('[data-kt-search-element="advanced-options-form-cancel"]');
            //advancedOptionsFormSearchElement = element.querySelector('[data-kt-search-element="advanced-options-form-search"]');
            
            // Initialize search handler
            searchObject = new KTSearch(element);

            // Search handler
            searchObject.on('kt.search.process', processs);

            // Clear handler
            searchObject.on('kt.search.clear', clear);

            $(formElement).on("submit", function(event){
                event.preventDefault();
            })
            // Custom handlers
            //handlePreferences();
            //handleAdvancedOptionsForm();
		}
	};
}();

// On document ready
KTUtil.onDOMContentLoaded(function() {
    KTLayoutSearch.init();
});